<template>
  <client-only>
    <WishlistPage :page="pageData" />
  </client-only>
</template>

<script lang="ts">
import Vue from "vue";
import { TokenableScheme } from "@nuxtjs/auth-next";
import { InitPageParams } from "lib/utils/initPage";
import {
  setNewRelicSpanAttributes,
  setNewRelicTransactionName,
} from "~~/lib/utils/logger/instrument";
import { getJsonLd } from "~/lib/mappers/JsonLdMapper";
import { initPage } from "~~/lib/utils/initPage";
import WishlistPage from "~/components/WishlistPage/WishlistPage.vue";
import { utag } from "~/components/ModularPage/ContentstackModularPageContentDecorator";
import { getBaseLink } from "~~/lib/utils/link/getBaseLink";

export default Vue.extend({
  name: "Wishlist",
  components: {
    WishlistPage,
  },
  async asyncData({ store, i18n, route, params, $config }): Promise<void> {
    const requestParams: InitPageParams = {
      locale_iso: i18n.localeProperties.iso,
      locale_code: i18n.locale,
      locale_domain: $config.public.baseUrl,
      path: route.path,
      slug: getBaseLink(route.path),
      currency_code: params.currencyCode || i18n.localeProperties.currencyCode,
    };

    setNewRelicTransactionName("Wishlist");
    setNewRelicSpanAttributes({
      locale: i18n.locale,
      currencyCode: params.currencyCode || i18n.localeProperties.currencyCode,
      path: route.path,
    });

    await Promise.all(initPage(store, requestParams));
  },
  computed: {
    pageData() {
      const strategy = this.$auth?.strategy as TokenableScheme;
      const isLoggedIn =
        strategy?.token?.status().valid() &&
        !strategy?.token?.status().expired();
      const name = this.$store.getters["user/firstName"];
      const jsonldData = getJsonLd({
        ...this.$config.public,
      })({
        "@context": "https://schema.org",
        "@type": "WebSite",
        url: this.$config.public.baseUrl,
        name: "Intrepid Travel",
      });
      const metaData = [
        {
          property: "og:url",
          content: `${this.$config.public.baseUrl}${this.$route.fullPath}`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: this.$config.public.defaultMetaImage,
        },
      ];
      return {
        jsonld: jsonldData,
        utag: utag(
          this.$i18n.localeProperties,
          this.$route.fullPath,
          "wishlist"
        ),
        metadata: metaData,
        title: "My wishlist | Intrepid Travel",
        banner: {
          title: isLoggedIn && name ? name + "'s wishlist" : "My wishlist",
        },
        breadcrumb: {
          description: "Breadcrumb of My wishlist page",
          items: [
            {
              title: "Home",
              href: "/",
            },
            {
              title: "My wishlist",
              href: undefined,
            },
          ],
        },
      };
    },
  },
});
</script>
